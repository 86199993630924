import { ArrowLeftOutlined, HistoryOutlined, LogoutOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { Dropdown, Menu, notification, Select } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineSmile } from 'react-icons/ai'
import { useNavigate } from "react-router-dom"
import { MainState } from '../../MainProvider'
import DropdownIcon from '../../images/header/dropdown-icon.svg'
import DropdownIconResponsive from '../../images/header/DropdownIconResponsive.svg'
import SmileIconResponsive from '../../images/header/SmileIconResponsive.svg'
import Logo from '../../images/header/logo.svg'
import { IoMdClose } from 'react-icons/io'
import "./styles.header.scss"
import { getCategories } from '../../api/category/categoryApi'
import { getClusterByCategoryId } from '../../api/clusters/clustersApi'
import { FaRegUser } from 'react-icons/fa'
import { getProfile } from '../../api/user/user'
const { Option } = Select;

const searchResultLst = [
    'Chung cư mini', 'Trấn thành', 'Giá xăng', 'Bóng đá'
]

const searchSource = [
    'Xe', 'Du lịch', 'Pháp luật', 'Ẩm thực', 'Phim & ảnh', 'Góc nhìn', 'Chính trị', 'Khoa học', 'Sách', 'Giáo dục', 'Nhà đẹp', 'Podcast'
]
const typeSource = [
    {
        name: 'Báo điện tử',
        type: 'WEBSITE_POST'
    },
    {
        name: 'Facebook',
        type: 'FB_POST'
    },
    {
        name: 'Youtube',
        type: 'YOUTUBE'
    },
    {
        name: 'Tiktok',
        type: 'TIKTOK'
    }
]

const typeCategory = [
    {
        name: 'Tích cực',
        type: 'POSITIVE'
    },
    {
        name: 'Tiêu cực',
        type: 'NEGATIVE'
    },
]
// Phần header của trang web
export const CHeader = () => {

    const { setTopicContent, topiclst, isResponsive, widthWeb, categorys, setCategorys, lstClusterByCategory, setLstClusterByCategory, lastest, setLastest, isLogin, setIsLogin, profile, setProfile } = MainState();

    const [visible, setVisible] = useState(false); // Biến thể hiện nút thu gọn menu có đang mở hay không
    const navigate = useNavigate(); // Hàm dùng để chuyển trang
    const [changeView, setChangeView] = useState<number>(1)

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [lstTopic, setLstTopic] = useState<any[]>([]); // Danh sách chủ đề
    const [searchResult, setSearchResult] = useState<string[]>(searchResultLst); // Danh sách kết quả tìm kiếm
    const [isNotShowHeader, setIsNotShowHeader] = useState(false); // Biến thể hiện header có đang được ẩn hay không

    const [isShowSearchResponsive, setIsShowSearchResponsive] = useState(false); // Biến thể hiện search có đang được hiển thị hay không

    // Hàm xử lý khi scroll chuột
    useEffect(() => {
        const handleScroll = () => {
            // const currentScrollPos = window.pageYOffset;
            const { scrollTop } = document.body;
            setPrevScrollPos(scrollTop);
        };

        document.body.addEventListener('scroll', handleScroll);
        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    // // Thay đổi trạng thái của header khi người dùng cuộn trang
    // useEffect(() => {
    //     const handleWindowResize = () => {
    //         setWindowSize([window.innerWidth, window.innerHeight]);
    //     };

    //     window.addEventListener("resize", handleWindowResize);
    //     if (window.innerWidth > 1920) {
    //     }
    //     return () => {
    //         window.removeEventListener("resize", handleWindowResize);
    //     };
    // });

    // Kiểm tra xem đường dẫn đang là gì để set thuộc tính đã click cho header
    useEffect(() => {
        if (window.location.pathname === '/') {
            setChangeView(1)
        }
        if (window.location.pathname === '/today-news') {
            setChangeView(2)
        }
        if (window.location.pathname === '/lastest-news' || window.location.pathname.includes('/lastest-news')) {
            setChangeView(3)
        }
        if (window.location.pathname.includes('/category') || window.location.pathname.includes('/detail-news') || window.location.pathname.includes('/topic') || window.location.pathname.includes('/user') || window.location.pathname.includes('/view-more') || window.location.pathname.includes('/login') || window.location.pathname.includes('/register') || window.location.pathname.includes('/coffee') || window.location.pathname.includes('/category')) {
            setChangeView(0)
        }
        if (window.location.pathname.includes('/detail-news')) {
            setIsNotShowHeader(true)
        }
        else
            setIsNotShowHeader(false)
    }, [window.location.pathname])

    // Lấy danh sách chủ đề
    useEffect(() => {
        setLstTopic(topiclst)
    }, [topiclst])

    useEffect(() => {
        getAllCategory()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const dropdownDiv = document.querySelector('.ant-select-dropdown.ant-select-dropdown-empty.ant-select-dropdown-placement-bottomRight');
            if (dropdownDiv && !dropdownDiv.contains(event.target)) {
                // Nếu có div, ẩn div đi
                dropdownDiv.classList.add('ant-select-dropdown-hidden');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (categorys && categorys.length > 0)
            getAllClussterByCategoryId(categorys)

    }, [categorys])

    useEffect(() => {
        if (isLogin) {
            getProfileUser()
        }
    }, [isLogin])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProfileUser()
        }
    }, [])

    const getProfileUser = async () => {
        await getProfile({ token: localStorage.getItem('token') })
            .then((res) => {
                console.log(res)
                setProfile(res.data.data)
            })
            .catch((err) => {
                console.log(err)

                if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('tokenExpiration');

                    notification.warning({
                        message: 'Phiên đăng nhập hết hạn',
                        // description: 'Vui lòng đăng nhập lại để tiếp tục.',
                        duration: 3,
                    });

                    // navigate('/login');
                }


                console.log(err)
            })
    }
    const handleMenuClick = (key: string) => {
        switch (key) {
            case 'history':
                navigate('/history-news');
                break;
            case 'settings':
                // Handle settings click
                break;
            case 'logout':
                // Handle logout
                notification.success({
                    message: 'Đăng xuất thành công',
                    description: 'Bạn đã đăng xuất thành công',
                })
                localStorage.clear()
                setIsLogin(false)
                setProfile(null)
                // navigate('/')
                break;
            default:
                break;
        }
    };

    const userMenu = (
        <Menu className="user-dropdown-menu" onClick={({ key }) => handleMenuClick(key as string)}>
            <Menu.Item key="history" icon={<HistoryOutlined />} className="user-dropdown-item">
                Lịch sử hỏi tin
            </Menu.Item>
            <Menu.Item key="settings" icon={<SettingOutlined />} className="user-dropdown-item">
                Thay đổi thông tin
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />} className="user-dropdown-item">
                Đăng xuất
            </Menu.Item>
        </Menu>
    );

    const getAllClussterByCategoryId = async (categorys: any) => {
        let tmp: any[] = [];
        for (let index = 0; index < categorys.length; index++) {
            const category = categorys[index];
            const req = {
                size: 1,
                offset: 0,
                categoryId: category.categoryId
            }
            await getClusterByCategoryId(req)
                .then((data) => {
                    console.log(data?.data?.data?.items);
                    if (data?.data?.data?.items && data?.data?.data?.items.length > 0) {
                        tmp.push({
                            category: category,
                            clusters: data?.data?.data?.items
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        if (tmp && tmp.length > 0) {
            setLstClusterByCategory(tmp);
        }
    }

    const getAllCategory = async () => {
        await getCategories()
            .then((data) => {
                console.log(data?.data?.data);
                if (data?.data?.data) {
                    setCategorys(data?.data?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }


    // Hiển thị ra nút thu gọn menu
    const showDrawer = () => {
        setVisible(!visible);
    }
    // Hàm xử lý khi chọn chủ đề bài viết
    const handleChangeValueSelect = (value: any) => {
        console.log(value);
        setTopicContent(value)
        if (value !== undefined) {
            navigate('/topic')
        }
    }
    // Hàm xử lý khi click vào logo
    const onClickLogo = () => {
        navigate('/');
    }

    const onClickLogin = () => {
        navigate('/login');
    }

    const handleRemoveItem = (itemToRemove: string) => {
        const newSearchResult = searchResult.filter(item => item !== itemToRemove);
        setSearchResult(newSearchResult);
    }

    const menuContent = (
        <>
            <div className='container-menu'>
                <div className='title'>Tìm kiếm gần đây</div>
                <div className='content'>
                    {(searchResult && searchResult.length) && searchResult.map((item, index) => (
                        <div className='item' key={index}>
                            <div className='item-text'>
                                {item}
                            </div>
                            <div className='item-close' onClick={() => handleRemoveItem(item)}>
                                <AiOutlineClose />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='title'>Chủ đề</div>
                <div className='content-category'>
                    <div className='items'>
                        {(lstClusterByCategory && lstClusterByCategory.length > 0) && lstClusterByCategory.map((item: any, index: any) => (
                            <div className="item"
                                onClick={() => {
                                    localStorage.setItem('category', JSON.stringify(item.category));
                                    const name = item.category.name.replace(/ /g, '-').toLowerCase();
                                    navigate(`/category/${name}`);
                                }}

                            >
                                <div className="item text">
                                    {item.category.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='items'>
                        {searchSource.map((item, index) => (
                            <div className='item' key={index}>
                                <div className='item-text'>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='title'>Nguồn</div>
                <div className='content-category-source'>
                    <div className='items'>
                        {(typeSource && typeSource.length > 0) && typeSource.map((item: any, index: any) => (
                            <div className="item"
                                onClick={() => {
                                    const dropdownDiv = document.querySelector('.ant-select-dropdown.ant-select-dropdown-empty.ant-select-dropdown-placement-bottomRight');
                                    const searchInput = document.querySelector('.ant-select-selection-search-input') as HTMLInputElement;
                                    if (searchInput) {
                                        // Nếu có input, set giá trị cho input
                                        searchInput.blur();
                                    }
                                    // if (dropdownDiv) {
                                    //     // Nếu có div, ẩn div đi
                                    //     dropdownDiv.classList.add('ant-select-dropdown-hidden');
                                    // }
                                    setLastest([]);
                                    navigate(`/lastest-news/${item.type}`);
                                }}
                            >
                                <div className="item text">
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='items'>
                    </div>
                </div>
                <div className='title'>Phân loại</div>
                <div className='content-category-source'>
                    <div className='items'>
                        {(typeCategory && typeCategory.length > 0) && typeCategory.map((item: any, index: any) => (
                            <div className="item"
                                onClick={() => {
                                    const dropdownDiv = document.querySelector('.ant-select-dropdown.ant-select-dropdown-empty.ant-select-dropdown-placement-bottomRight');
                                    const searchInput = document.querySelector('.ant-select-selection-search-input') as HTMLInputElement;
                                    if (searchInput) {
                                        // Nếu có input, set giá trị cho input
                                        searchInput.blur();
                                    }
                                    // if (dropdownDiv) {
                                    //     // Nếu có div, ẩn div đi
                                    //     dropdownDiv.classList.add('ant-select-dropdown-hidden');
                                    // }
                                    setLastest([]);
                                    navigate(`/lastest-news/classification:${item.type}`);
                                }}
                            >
                                <div className="item text">
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='items'>
                    </div>
                </div>
            </div>
        </>
    )

    const menuContentResponsive = (
        <>
            <div className='container-menu responsive'>
                <div className='title'>Tìm kiếm gần đây</div>
                <div className='content'>
                    {(searchResult && searchResult.length) && searchResult.map((item, index) => (
                        <div className='item' key={index}>
                            <div className='item-text'>
                                {item}
                            </div>
                            <div className='item-close' onClick={() => handleRemoveItem(item)}>
                                <AiOutlineClose />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )

    return (
        <>
            {(widthWeb > 1100) &&
                <>
                    <motion.div className={'main-header'}
                    >
                        <motion.div className='header-info'
                        >
                            <motion.div className="header-left"
                            >
                                <div className='header-logo' onClick={onClickLogo}>
                                    <img src={Logo} alt="" />
                                </div>
                                <div className='pages'>
                                    <div className={'page-item ' + (changeView === 1 && 'active')}
                                        onClick={() => {
                                            setChangeView(1)
                                            navigate('/')
                                        }}
                                    >Trang chủ</div>
                                    <div className={'page-item ' + (changeView === 2 && 'active')}
                                        onClick={() => {
                                            setChangeView(2)
                                            navigate('/today-news')
                                        }}
                                    >8am hôm nay</div>
                                    <div className={'page-item ' + (changeView === 3 && 'active')}
                                        onClick={() => {
                                            setChangeView(3)
                                            navigate('/lastest-news')
                                        }}
                                    >Mới nhất</div>
                                </div>
                            </motion.div>
                            <motion.div className='header-content-input'>
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    suffixIcon={<img src={DropdownIcon} alt='' />}
                                    onChange={handleChangeValueSelect}
                                    placeholder={<span className='placeholder-select'>Tìm kiếm bài viết, chủ đề & nguồn</span>}
                                    notFoundContent={menuContent}
                                >
                                    {lstTopic?.map((index, item) => (
                                        <Option key={index.id} value={index.id}>{index.name}</Option>
                                    ))}
                                </Select>
                            </motion.div>
                            <motion.div className="header-right">
                                <motion.div className="header-right">
                                    {profile ? (
                                        <Dropdown overlay={userMenu} placement="bottomRight" trigger={['hover']}>
                                            <div className='user-infor'>
                                                {
                                                    profile.avatar ? (
                                                        <img src={profile.avatar} alt="" className='avatar' />
                                                    ) : (
                                                        <FaRegUser />
                                                    )
                                                }
                                                {profile.name}
                                            </div>
                                        </Dropdown>
                                    ) : (
                                        <div className='user-infor' onClick={onClickLogin}>
                                            <AiOutlineSmile />
                                            Đăng nhập
                                        </div>
                                    )}
                                </motion.div>
                                {isResponsive &&
                                    <div className='menubtn' onClick={showDrawer}>
                                        <SearchOutlined />
                                    </div>
                                }
                            </motion.div>
                        </motion.div>
                    </motion.div >
                    <AnimatePresence>
                        {
                            visible &&
                            <motion.div
                                className='search-active'
                                initial={{ x: 800 }}
                                animate={{ x: 0 }}
                                exit={{ x: 800, transition: { duration: 0.5 } }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}>
                                <ArrowLeftOutlined onClick={showDrawer} />
                                <div className='search-input-content'>
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        suffixIcon={<img src={DropdownIcon} alt='' />}
                                        onChange={handleChangeValueSelect}
                                        placeholder="Tìm kiếm bài viết, chủ đề & nguồn"
                                        notFoundContent={menuContent}

                                    >
                                        {lstTopic.map((index) => (
                                            <Option value={index.id}>{index.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='pages-responsive'>
                                    <div className={'page-item ' + (changeView === 1 && 'active')} onClick={() => setChangeView(1)}>Trang chủ</div>
                                    <div className={'page-item ' + (changeView === 2 && 'active')} onClick={() => setChangeView(2)}>8am hôm nay</div>
                                    <div className={'page-item ' + (changeView === 3 && 'active')} onClick={() => setChangeView(3)}>Mới nhất</div>
                                </div>
                            </motion.div>}
                    </AnimatePresence>
                </>
            }
            {(widthWeb <= 1100 && widthWeb > 450) &&
                <>
                    <motion.div className={'main-header-responsive width-700 '}
                    >
                        <div className='header-info-left' onClick={() => {
                            setIsShowSearchResponsive(true)
                        }}>
                            <img src={DropdownIconResponsive} alt="" />
                        </div>
                        <div className='header-info-center' onClick={onClickLogo}>
                            <img src={Logo} alt="" />
                        </div>
                        <div className='header-info-right'>
                            {/* <div className='user-infor' onClick={onClickLogin}>
                                <AiOutlineSmile />
                                Đăng nhập
                            </div> */}

                            {profile ? (
                                <Dropdown overlay={userMenu} placement="bottomRight" trigger={['hover']}>
                                    <div className='user-infor'>
                                        {
                                            profile.avatar ? (
                                                <img src={profile.avatar} alt="" className='avatar' />
                                            ) : (
                                                <FaRegUser />
                                            )
                                        }
                                        {profile.name}
                                    </div>
                                </Dropdown>
                            ) : (
                                <div className='user-infor' onClick={onClickLogin}>
                                    <AiOutlineSmile />
                                    Đăng nhập
                                </div>
                            )}
                        </div>
                    </motion.div >
                    <AnimatePresence>
                        {
                            isShowSearchResponsive &&
                            <motion.div
                                className='search-active-responsive'
                                initial={{ x: -800 }}
                                animate={{ x: 0 }}
                                exit={{ x: -800, transition: { duration: 0.5 } }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}>

                                <div className='title'>
                                    <img src={Logo} alt="" />
                                    <IoMdClose className='icon-close' onClick={() => setIsShowSearchResponsive(false)} />
                                </div>

                                <div className='search-input-content'>
                                    <Select
                                        showSearch
                                        // allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        suffixIcon={<img src={DropdownIcon} alt='' />}
                                        onChange={handleChangeValueSelect}
                                        placeholder="Tìm kiếm bài viết, chủ đề & nguồn"
                                        notFoundContent={menuContentResponsive}
                                    >
                                        {lstTopic.map((index) => (
                                            <Option value={index.id}>{index.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='pages-responsive'>
                                    <div className={'page-item '} onClick={() => {
                                        navigate('/')
                                        setIsShowSearchResponsive(false)
                                    }}>Trang chủ</div>
                                    <div className={'page-item '} onClick={() => {
                                        navigate('/today-news')
                                        setIsShowSearchResponsive(false)
                                    }}>8am hôm nay</div>
                                    <div className={'page-item '} onClick={() => {
                                        navigate('/lastest-news')
                                        setIsShowSearchResponsive(false)
                                    }}>Mới nhất</div>

                                    <div className='line-break'></div>
                                </div>
                                <div className='pages-responsive'>
                                    {(lstClusterByCategory && lstClusterByCategory.length > 0) && lstClusterByCategory.map((item: any, index: any) => (
                                        <div className="page-item"
                                            onClick={() => {
                                                localStorage.setItem('category', JSON.stringify(item.category));
                                                const name = item.category.name.replace(/ /g, '-').toLowerCase();

                                                navigate(`/category/${name}`);
                                            }}

                                        >{item.category.name}</div>
                                    ))}
                                    <div className='line-break'></div>
                                </div>
                                <div className='pages-responsive'>
                                    {(typeSource && typeSource.length > 0) && typeSource.map((item: any, index: any) => (
                                        <div className="page-item"
                                            onClick={() => {
                                                setLastest([]);
                                                navigate(`/lastest-news/${item.type}`);
                                                setIsShowSearchResponsive(false)
                                            }}
                                        >{item.name}</div>
                                    ))}
                                    <div className='line-break'></div>
                                </div>
                                <div className='pages-responsive'>
                                    {(typeCategory && typeCategory.length > 0) && typeCategory.map((item: any, index: any) => (
                                        <div className="page-item"
                                            onClick={() => {
                                                setLastest([]);
                                                navigate(`/lastest-news/classification:${item.type}`);
                                                setIsShowSearchResponsive(false)
                                            }}
                                        >{item.name}</div>
                                    ))}
                                    <div className='line-break'></div>
                                </div>
                            </motion.div>}
                    </AnimatePresence>
                </>
            }
            {(widthWeb <= 450) &&
                <>
                    <motion.div className={'main-header-responsive ' + (isNotShowHeader && 'hide-header')}
                    >
                        <div className='header-info-left' onClick={() => {
                            setIsShowSearchResponsive(true)
                        }}>
                            <img src={DropdownIconResponsive} alt="" />
                        </div>
                        <div className='header-info-center'>
                            <img src={Logo} alt="" />
                        </div>
                        <div className='header-info-right' >
                            {profile ? (
                                <Dropdown overlay={userMenu} placement="bottomRight" trigger={['hover']}>
                                    <div className='user-infor'>
                                        {
                                            profile.avatar ? (
                                                <img src={profile.avatar} alt="" className='avatar' />
                                            ) : (
                                                <FaRegUser />
                                            )
                                        }
                                        {/* {profile.name} */}
                                    </div>
                                </Dropdown>
                            ) : (
                                <div className='user-infor' onClick={onClickLogin}>
                                    <AiOutlineSmile />
                                    {/* Đăng nhập */}
                                </div>
                            )}
                            {/* <img src={SmileIconResponsive} alt="" /> */}
                        </div>
                    </motion.div >
                    <AnimatePresence>
                        {
                            isShowSearchResponsive &&
                            <motion.div
                                className='search-active-responsive'
                                initial={{ x: -800 }}
                                animate={{ x: 0 }}
                                exit={{ x: -800, transition: { duration: 0.5 } }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}>

                                <div className='title'>
                                    <img src={Logo} alt="" />
                                    <IoMdClose className='icon-close' onClick={() => setIsShowSearchResponsive(false)} />
                                </div>

                                <div className='search-input-content'>
                                    <Select
                                        showSearch
                                        // allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        suffixIcon={<img src={DropdownIcon} alt='' />}
                                        onChange={handleChangeValueSelect}
                                        placeholder="Tìm kiếm bài viết, chủ đề & nguồn"
                                        notFoundContent={menuContentResponsive}
                                    >
                                        {lstTopic.map((index) => (
                                            <Option value={index.id}>{index.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='pages-responsive'>
                                    <div className={'page-item '} onClick={() => {
                                        navigate('/')
                                        setIsShowSearchResponsive(false)
                                    }}>Trang chủ</div>
                                    <div className={'page-item '} onClick={() => {
                                        navigate('/today-news')
                                        setIsShowSearchResponsive(false)
                                    }}>8am hôm nay</div>
                                    <div className={'page-item '} onClick={() => {
                                        navigate('/lastest-news')
                                        setIsShowSearchResponsive(false)
                                    }}>Mới nhất</div>

                                    <div className='line-break'></div>
                                </div>
                                {
                                    (lstClusterByCategory && lstClusterByCategory.length > 0) &&
                                    <div className='pages-responsive'>
                                        {lstClusterByCategory.map((item: any, index: any) => (
                                            <div className="page-item"
                                                onClick={() => {
                                                    localStorage.setItem('category', JSON.stringify(item.category));
                                                    const name = item.category.name.replace(/ /g, '-').toLowerCase();
                                                    navigate(`/category/${name}`);
                                                    setIsShowSearchResponsive(false)
                                                }}
                                            >{item.category.name}</div>
                                        ))}
                                        <div className='line-break'></div>
                                    </div>
                                }
                                {
                                    (typeSource && typeSource.length > 0) &&
                                    <div className='pages-responsive'>
                                        {typeSource.map((item: any, index: any) => (
                                            <div className="page-item"
                                                onClick={() => {
                                                    setLastest([]);
                                                    navigate(`/lastest-news/${item.type}`);
                                                    setIsShowSearchResponsive(false)
                                                }}
                                            >{item.name}</div>
                                        ))}
                                        <div className='line-break'></div>
                                    </div>
                                }
                                {
                                    (typeCategory && typeCategory.length > 0) &&
                                    <div className='pages-responsive'>
                                        {typeCategory.map((item: any, index: any) => (
                                            <div className="page-item"
                                                onClick={() => {
                                                    setLastest([]);
                                                    navigate(`/lastest-news/classification:${item.type}`);
                                                    setIsShowSearchResponsive(false)
                                                }}
                                            >{item.name}</div>
                                        ))}
                                        <div className='line-break'></div>
                                    </div>
                                }
                            </motion.div>}
                    </AnimatePresence>
                </>
            }

        </>
    )
}
